import {errorHttpMessage} from "@/utils/errorDecodeUtil";
import configurationClient from '@/axiosClients/configurationClient';
import reportClient from "@/axiosClients/reportClient";
import operationClient from "@/axiosClients/operationClient";
import report2Client from "@/axiosClients/report2Client";

export const getSubgroupsList = async ({commit}) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/subgroups`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const getSpecialLabelsList = async ({commit}) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/speciallabels`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const getReportsLists = async ({commit}) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/show`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const getReportsTemplateList = async ({commit}) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/custom/desc/100`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const getColumnsReport = async ({commit}) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/columnsreport`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const save = async ({commit}, reportTemplate) => {
    try {
        const {data: result} = await reportClient.post(`/data-mining/create`, reportTemplate);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const deleteById = async ({commit}, reportId) => {
    try {
        const {data: result} = await reportClient.delete(`/data-mining/${reportId}`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const getById = async ({commit}, reportId) => {
    try {
        const {data: result} = await reportClient.get(`/data-mining/show/${reportId}`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const edit = async ({commit}, reportTemplate) => {
    try {
        const {data: result} = await reportClient.post(`/data-mining/${reportTemplate.id}`, reportTemplate);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        };
    }
}

export const genereteReport = async ({commit}, parameters) => {
    try {

        //report, tablesSelected

        //Determ which endpoint will be called, it could be old or new app-report microservice
        const parametersFilters = parameters.tablesSelected.value
        const parametersFiltersNewDataMinning = [
            [1, 7],
            [1, 24],
            [1, 4],
            [1,8]
        ]

        //Check if the combination of filters is from parametersFiltersNewDataMinning
        var callNewMicroserviceReport = false;
        for (let i = 0; i < parametersFiltersNewDataMinning.length; i++) {
            if (!callNewMicroserviceReport) {
                callNewMicroserviceReport = parametersFiltersNewDataMinning[i].every(value => parametersFilters.includes(value));
            }
        }

        //If user only call data to indentify patient, call new microservice report
        if (parametersFilters.length == 1) {
            if (parametersFilters[0] == 1) {
                callNewMicroserviceReport = true;
            }
        }

        if (callNewMicroserviceReport) {
            var {data: result} = await report2Client.post(`/data-mining/generate/${parameters.report.id}`, parameters.report);
        } else {
            var {data: result} = await reportClient.post(`/data-mining/generate/${parameters.report.id}`, parameters.report);
        }

        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (error) {
        const error_http_message = errorHttpMessage(error);
        console.log(error_http_message)
        console.log(error.response)
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: error.response.message,
            error: true
        };
    }
}

export const getFilters = async ({commit}) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/data-mining`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
