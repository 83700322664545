import store from '@/store'
import sweetalertUtil from '@/utils/sweetalertUtil'
import i18n from '@/translations'
import {checkPermission} from '@/utils/permissionsUtil'

const verifyTokenGuard = async (to, from, next) => {

    const token = store.getters["auth/getToken"]
    const isExpired = store.getters['auth/isExpired']
    const loggedUser = store.getters['auth/getLoggedUser']
    const isNeedsAcceptNoticeOfPrivacy = store.getters['auth/getIsNeedsAcceptNoticeOfPrivacy']

    if (isNeedsAcceptNoticeOfPrivacy) {
        next({name: 'notice_privacy-index'})
    } else if (isExpired) {

        const {message, error} = await store.dispatch('auth/refreshToken')

        if (error) {

            await store.dispatch('auth/clearAuthToken')

            if (message != "") {

                sweetalertUtil.fire({
                    icon: 'error',
                    title: i18n.global.messages.value[i18n.global.locale.value]["alert-title-message"],
                    html: message,
                    confirmButtonText: i18n.global.messages.value[i18n.global.locale.value]["accept"],
                })

            }

            next({name: 'auth-login'})
        }

        if (checkPermission(to.name)) {
            next()
        } else {

            sweetalertUtil.fire({
                icon: 'error',
                title: 'Error',
                html: i18n.global.messages.value[i18n.global.locale.value]['errors']['has-permission'],
            })
            next({name: from.name})
        }

    } else if (!token || isExpired) {
        next({name: 'auth-login'})
    } else {

        //Needs to change password
        const passwordExpired = store.getters['auth/isPasswordExpired']

        if (passwordExpired) {
            next({name: 'change-password-expired-edit'})
        } else if (checkPermission(to.name)) {
            next()
        } else {
            sweetalertUtil.fire({
                icon: 'error',
                title: 'Error',
                html: i18n.global.messages.value[i18n.global.locale.value]['errors']['has-permission'],
            })
            next({name: from.name})
        }

    }

}

export default verifyTokenGuard
